// フロントエンドで用いる定数をまとめておく

interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

interface Mqtt {
  announceTopic: string
  messageTopicPrefix: string
}

interface Constants {
  mock: {
    hpcrOrigin: string
    bibgraphOrigin: string
    dcsrOrigin: string
    googleMapKey: string
    mqtt: Mqtt
  }
  local: {
    s3EncryptedBucket: string
    hpcrOrigin: string
    bibgraphOrigin: string
    dcsrOrigin: string
    googleMapKey: string
    mqtt: Mqtt
    firebase: FirebaseConfig
  }
  dev: {
    s3EncryptedBucket: string
    hpcrOrigin: string
    bibgraphOrigin: string
    dcsrOrigin: string
    googleMapKey: string
    mqtt: Mqtt
    firebase: FirebaseConfig
  }
  stg: {
    s3EncryptedBucket: string
    hpcrOrigin: string
    bibgraphOrigin: string
    dcsrOrigin: string
    googleMapKey: string
    mqtt: Mqtt
    firebase: FirebaseConfig
  }
  qa: {
    s3EncryptedBucket: string
    hpcrOrigin: string
    bibgraphOrigin: string
    dcsrOrigin: string
    googleMapKey: string
    mqtt: Mqtt
    firebase: FirebaseConfig
  }
  prod: {
    s3EncryptedBucket: string
    hpcrOrigin: string
    bibgraphOrigin: string
    dcsrOrigin: string
    googleMapKey: string
    mqtt: Mqtt
    firebase: FirebaseConfig
  }
}

const constants: Constants = {
  mock: {
    hpcrOrigin: 'http://localhost:3000',
    bibgraphOrigin: 'https://bibgraph-dev.xmdev.io',
    dcsrOrigin: 'http://localhost:3000',
    googleMapKey: 'AIzaSyCRhpOOOiyIV6o5_QzdZ8YfQOw7mp66p18',
    mqtt: {
      announceTopic: 'xmdev/announce',
      messageTopicPrefix: 'xmdev/session'
    }
  },
  local: {
    s3EncryptedBucket: 'docsiru-service-encrypted-files-devel',
    hpcrOrigin: 'http://localhost:3000',
    bibgraphOrigin: 'https://bibgraph-dev.xmdev.io',
    dcsrOrigin: 'http://localhost:3000',
    googleMapKey: 'AIzaSyCRhpOOOiyIV6o5_QzdZ8YfQOw7mp66p18',
    mqtt: {
      announceTopic: 'xmdev/announce',
      messageTopicPrefix: 'xmdev/session'
    },
    firebase: {
      apiKey: 'AIzaSyCMOx4UTcTQ_LPrc1BqgPrcHx_qHMU371g',
      authDomain: 'docshiru-dev.firebaseapp.com',
      projectId: 'docshiru-dev',
      storageBucket: 'docshiru-dev.appspot.com',
      messagingSenderId: '957261980375',
      appId: '1:957261980375:web:0323f1467f6feea428c692',
      measurementId: 'G-L9K0WR4Z9Q'
    }
  },
  dev: {
    s3EncryptedBucket: 'docsiru-service-encrypted-files-devel',
    hpcrOrigin: 'https://dev.docshiru.com',
    bibgraphOrigin: 'https://bibgraph-dev.xmdev.io',
    dcsrOrigin: 'https://dev.docshiru.com',
    googleMapKey: 'AIzaSyCRhpOOOiyIV6o5_QzdZ8YfQOw7mp66p18',
    mqtt: {
      announceTopic: 'xmdev/announce',
      messageTopicPrefix: 'xmdev/session'
    },
    firebase: {
      apiKey: 'AIzaSyCMOx4UTcTQ_LPrc1BqgPrcHx_qHMU371g',
      authDomain: 'docshiru-dev.firebaseapp.com',
      projectId: 'docshiru-dev',
      storageBucket: 'docshiru-dev.appspot.com',
      messagingSenderId: '957261980375',
      appId: '1:957261980375:web:0323f1467f6feea428c692',
      measurementId: 'G-L9K0WR4Z9Q'
    }
  },
  stg: {
    s3EncryptedBucket: 'docsiru-service-encrypted-files-devel',
    hpcrOrigin: 'https://stg.docshiru.com',
    bibgraphOrigin: 'https://bibgraph-dev.xmdev.io', // TODO: change to stg
    dcsrOrigin: 'https://stg.docshiru.com',
    googleMapKey: 'AIzaSyCRhpOOOiyIV6o5_QzdZ8YfQOw7mp66p18',
    mqtt: {
      announceTopic: 'xmdev/announce',
      messageTopicPrefix: 'xmdev/session'
    },
    firebase: {
      apiKey: 'AIzaSyCMOx4UTcTQ_LPrc1BqgPrcHx_qHMU371g',
      authDomain: 'docshiru-dev.firebaseapp.com',
      projectId: 'docshiru-dev',
      storageBucket: 'docshiru-dev.appspot.com',
      messagingSenderId: '957261980375',
      appId: '1:957261980375:web:0323f1467f6feea428c692',
      measurementId: 'G-L9K0WR4Z9Q'
    }
  },
  qa: {
    s3EncryptedBucket: 'docsiru-service-encrypted-files-qa',
    hpcrOrigin: 'https://qa.docshiru.com',
    bibgraphOrigin: 'https://bibgraph.hpcr.jp', // TODO: change to qa
    dcsrOrigin: 'https://qa.docshiru.com',
    googleMapKey: 'AIzaSyCIAfK38JLy1UWVxN3YYBOuTkIT_J--uGs',
    mqtt: {
      announceTopic: 'xmd/announce',
      messageTopicPrefix: 'xmd/session'
    },
    firebase: {
      apiKey: 'AIzaSyAsfz-MQPP3j4SscnqDNHaR_pNsMptfCL0',
      authDomain: 'docshiru-63135.firebaseapp.com',
      projectId: 'docshiru-63135',
      storageBucket: 'docshiru-63135.appspot.com',
      messagingSenderId: '984894856031',
      appId: '1:984894856031:web:5b0f1ded05e1b4073374ea',
      measurementId: 'G-5NH6ZJ4PLX'
    }
  },
  prod: {
    s3EncryptedBucket: 'docsiru-service-encrypted-files',
    hpcrOrigin: 'https://docshiru.com',
    bibgraphOrigin: 'https://bibgraph.hpcr.jp',
    dcsrOrigin: 'https://docshiru.com',
    googleMapKey: 'AIzaSyCIAfK38JLy1UWVxN3YYBOuTkIT_J--uGs',
    mqtt: {
      announceTopic: 'xmd/announce',
      messageTopicPrefix: 'xmd/session'
    },
    firebase: {
      apiKey: 'AIzaSyAsfz-MQPP3j4SscnqDNHaR_pNsMptfCL0',
      authDomain: 'docshiru-63135.firebaseapp.com',
      projectId: 'docshiru-63135',
      storageBucket: 'docshiru-63135.appspot.com',
      messagingSenderId: '984894856031',
      appId: '1:984894856031:web:5b0f1ded05e1b4073374ea',
      measurementId: 'G-5NH6ZJ4PLX'
    }
  }
}

interface Commons {
  shownAnalyzingModalKey: string
  shownListTutorialModalKey: string
  shownChatTermsModalKey: string
  shownNoticeTermsModalKey: string
  shownNewMypageHelpTooltipKey: string
  shownBusinessCardTutorialModalKey: string
  isViewedBusinessCardHolderKey: string
  isViewedMypageSelectSearchKey: string
  isViewedBusinessCardRequestsKey: string
  isShowResponseNoticeKey: string
  redirectKey: string
  countOfHospitalsDisplayedOnMap: number
  roles: {
    doctor: string
    coMedical: string
    ms: string
  }
  url: {
    privacy: string
    about: string
    howToRegister: string
  }
  email: {
    contact: string
  }
  mailTypeLabelMap: {
    /* eslint-disable camelcase */
    direct_message_notification: string
    group_message_notification: string
    hospital_notification: string
    community_notification: string
    community_comment_notification: string
    /* eslint-disable camelcase */
  }
  firebaseViewEvents: {
    [key: string]: string
  }
}

const commons: Commons = {
  shownAnalyzingModalKey: 'SHOWN_ANALYZING_MODAL',
  shownListTutorialModalKey: 'SHOWN_LIST_TUTORIAL_MODAL',
  shownChatTermsModalKey: 'SHOWN_CHAT_TERMS_MODAL',
  shownNoticeTermsModalKey: 'SHOWN_NOTICE_TERMS_MODAL',
  shownNewMypageHelpTooltipKey: 'SHOWN_MYPAGE_HELP_TOOLTIP',
  shownBusinessCardTutorialModalKey: 'SHOWN_BUSINESS_CARD_TUTORIAL_MODAL',
  isViewedBusinessCardHolderKey: 'IS_VIEWED_BUSINESS_CARD_HOLDER',
  isViewedMypageSelectSearchKey: 'IS_VIEWED_MYPAGE_SELECT_SEARCH',
  isViewedBusinessCardRequestsKey: 'IS_VIEWED_BUSINESS_CARD_REQUESTS',
  isShowResponseNoticeKey: 'IS_SHOW_RESPONSE_NOTICE',
  redirectKey: 'REDIRECT',
  countOfHospitalsDisplayedOnMap: 20,
  roles: {
    doctor: 'doctor',
    coMedical: 'co-medical',
    ms: 'ms'
  },
  url: {
    privacy: 'https://hpcr.jp/product/privacy_policy/',
    about: 'https://gekkaworks.co.jp/',
    howToRegister: 'https://info.docshiru.com/manual/sign-up'
  },
  email: {
    contact: 'info@docshiru.com'
  },
  mailTypeLabelMap: {
    direct_message_notification: 'ダイレクトメッセージの新着メール',
    group_message_notification: 'グループメッセージの未読通知メール',
    hospital_notification: '医療機関からのお知らせ新着メール',
    community_notification: 'コミュニティの投稿通知メール',
    community_comment_notification: 'コミュニティ投稿へのコメント通知メール'
  },

  // see https://docs.google.com/spreadsheets/d/1j3O0FlFqvOe8tOhI3LJVYdUmOzOIVnop_B8-qVXthis/edit#gid=1843620922
  //  https://docs.google.com/spreadsheets/d/1XBM1G-sjnhLbr4DzDDgAB89zA9no7Grqw-F4J5cjygY/edit#gid=0
  // urlの/は-に変換される, topの/はindexになる /_idは-idになる

  firebaseViewEvents: {
    index: 'view_top',
    login: 'view_login',
    signup: 'view_signup',
    confirm: 'view_confirm',
    profile: 'view_profile',
    'medical-signup': 'view_medical_signup',
    'medical-confirm': 'view_medical_confirm',
    'medical-profile': 'view_medical_profile',
    passwordSetting: 'view_migration_password_setting',
    'migration-registered': 'view_migration_registered',
    'migration-complete': 'view_migration_complete',
    terms: 'view_terms',
    'setting-mail_magazine': 'view_setting_mail_magazine',
    'hospitals-search': 'view_hospitals_search',
    'doctor-search': 'view_doctor_search',
    'add-home': 'view_add-home',
    contact: 'view_contact',
    doctor: 'view_doctor',
    'doctor-id': 'view_doctor_profile',
    'doctor-id-edit': 'view_doctor_edit_profile',
    'doctor-id-edit-email': 'view_doctor_edit_profile_email',
    'doctor-id-edit-category': 'view_doctor_edit_profile_category',
    'doctor-id-edit-doctorExpertCategory':
      'view_doctor_edit_profile_doctorExpertCategory',
    'doctor-id-edit-searchPaperMeshList':
      'view_doctor_edit_profile_searchPaperMeshList',
    'doctor-id-edit-surgeryMeshList':
      'view_doctor_edit_profile_surgeryMeshList',
    'doctor-id-edit-unacceptableMeshList':
      'view_doctor_edit_profile_unacceptableMeshList',
    'doctor-id-edit-hospitals': 'view_doctor_edit_profile_hospitals',
    'doctor-new-select': 'view_doctor_new_select',
    'doctor-new-profile': 'view_doctor_new_profile',
    'doctor-new-profile-doctorExpertCategory':
      'view_doctor_new_profile_doctorExpertCategory',
    'doctor-new-profile-searchPaperMeshList':
      'view_doctor_new_profile_searchPaperMeshList',
    'doctor-new-profile-surgeryMeshList':
      'view_doctor_new_profile_surgeryMeshList',
    'doctor-new-profile-unacceptableMeshList':
      'view_doctor_new_profile_unacceptableMeshList',
    'group-id': 'view_group',
    'group-id-member': 'view_group_member',
    'group-member-add': 'view_group_member_add',
    'group-new': 'view_group_new',
    'group-notice-id-reaction': 'view_group_notice_reaction',
    'group-notice-new': 'view_group_notice_new',
    hospitals: 'view_hospitals',
    'hospitals-id': 'view_hospitals_detail',
    'hospitals-edit': 'view_hospitals_edit',
    'hospitals-edit-bed': 'view_hospitals_edit_bed',
    'hospitals-edit-medical_staff': 'view_hospitals_edit_medical_staff',
    'hospitals-edit-outpatient': 'view_hospitals_edit_outpatient',
    'hospitals-edit-outpatient-edit': 'view_hospitals_edit_outpatient_edit',
    'hospitals-edit-patient_date': 'view_hospitals_edit_patient_date',
    'hospitals-edit-result': 'view_hospitals_edit_result',
    'hospitals-edit-open_bed-id': 'view_hospitals_edit_open_bed_edit',
    'hospitals-edit-open_bed-new': 'view_hospitals_edit_open_bed_new',
    'hospitals-edit-open_bed': 'view_hospitals_edit_open_bed',
    invitation: 'view_invitation',
    'lists-doctors': 'view_lists_doctors',
    'lists-doctors-id': 'view_lists_doctors_detail',
    'lists-doctors-add': 'view_lists_doctors_add',
    'lists-hospitals': 'view_lists_hospitals',
    'lists-hospitals-id': 'view_lists_hospitals_detail',
    'lists-hospitals-add': 'view_lists_hospitals_add',
    'lists-new': 'view_lists_new',
    notices: 'view_notices',
    'notices-id': 'view_notices_detail',
    'notices-new': 'view_notices_new',
    'notices-new-select': 'view_notices_new_select',
    notification: 'view_notification',
    'notification-id': 'view_notification_detail',
    'papers-id': 'view_papers',
    rooms: 'view_rooms',
    'rooms-id': 'view_rooms_detail',
    'rooms-id-edit': 'view_rooms_edit',
    'rooms-id-members-add': 'view_rooms_members_add',
    'rooms-id-members': 'view_rooms_members',
    'rooms-new': 'view_rooms_new',
    search: 'view_search',
    'top-medical': 'view_top_medical',
    'top-ms': 'view_top_ms',
    'doctor-new': 'view_doctor_new_name',
    'doctor-new-profile-editHospital-id': 'view_doctor_new_hospital',
    'doctor-new-profile-pending': 'view_doctor_new_pending',
    'group-notice-id': 'view_group_notice',
    'group-id-notice-new': 'view_group_notice_new',
    'contact-form': 'view_contact_form',
    'agree-terms': 'view_agree_terms',
    'doctor-new-confirm': 'view_doctor_new_confirm',
    'regist-confirm': 'view_regist_confirm',
    welcome: 'view_welcome',
    mypage: 'view_mypage',
    'ms-id': 'view_ms_profile',
    'ms-id-edit': 'view_ms_edit_profile',
    'business-card-holder': 'view_business-card_holder',
    'business-card-requests': 'view_business-card_requests',
    'mypage-search': 'view_mypage_search',
    'ms-search': 'view_ms_search'
  }
}

const stage: 'mock' | 'local' | 'dev' | 'stg' | 'qa' | 'prod' =
  (process.env.STAGE as
    | 'mock'
    | 'local'
    | 'dev'
    | 'stg'
    | 'qa'
    | 'prod'
    | undefined) || 'local'

export default {
  ...commons,
  ...constants[stage],
  stage
}
