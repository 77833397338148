export const ListTypes: { value: number; text: string; type: string }[] = [
  {
    value: 1,
    text: '医療機関リスト：患者の紹介・逆紹介先',
    type: 'hospital'
  },
  {
    value: 2,
    text: '医療機関リスト：医療機関連携／営業先',
    type: 'hospital'
  }
]

export const HospitalLabels: {
  value: number
  text: string
  types: number[]
}[] = [
  {
    value: 1,
    text: '特定の患者用',
    types: [1]
  },
  {
    value: 2,
    text: '地域',
    types: [1]
  },
  {
    value: 3,
    text: '診療科',
    types: [1]
  },
  {
    value: 4,
    text: '疾患',
    types: [1]
  },
  {
    value: 7,
    text: '病診連携',
    types: [2]
  },
  {
    value: 8,
    text: '病病連携',
    types: [2]
  },
  {
    value: 9,
    text: '営業先候補',
    types: [2]
  },
  {
    value: 10,
    text: '集患',
    types: [2]
  },
  {
    value: 5,
    text: '一時利用するリスト',
    types: [1, 2]
  },
  {
    value: 6,
    text: '継続利用するリスト',
    types: [1, 2]
  }
]

export const DoctorLabels: { value: number; text: string }[] = [
  {
    value: 1,
    text: '地域'
  },
  {
    value: 2,
    text: '診療科'
  },
  {
    value: 3,
    text: '疾患'
  },
  {
    value: 4,
    text: '病診連携'
  },
  {
    value: 5,
    text: '病病連携'
  },
  {
    value: 6,
    text: '営業候補'
  },
  {
    value: 7,
    text: '集患'
  },
  {
    value: 8,
    text: 'リクルート'
  },
  {
    value: 9,
    text: '大学つながり'
  },
  {
    value: 10,
    text: '職場つながり'
  },
  {
    value: 11,
    text: '開業医'
  },
  {
    value: 12,
    text: '医師会'
  },
  {
    value: 13,
    text: '一時利用するリスト'
  },
  {
    value: 14,
    text: '継続利用するリスト'
  }
]

export const RoleList: { value: string; text: string }[] = [
  { value: 'doctor', text: '医師' },
  { value: 'pharmacist', text: '薬剤師' },
  { value: 'nurse', text: '看護師' },
  { value: 'medical-social-worker', text: '医療ソーシャルワーカー' },
  { value: 'office-worker', text: '事務職' },
  { value: 'other', text: 'その他' }
]

export const FORMAT_DATE: {
  YEAR: string
  MONTH: string
  DAY: string
  HOUR: string
  MINUTES: string
  TIME_DELIMITER: string
} = {
  YEAR: 'YYYY年',
  MONTH: 'M月',
  DAY: 'D日',
  HOUR: 'H',
  MINUTES: 'mm',
  TIME_DELIMITER: ':'
}

export const FORMAT_DATE_MONTH: string = `${FORMAT_DATE.YEAR}${FORMAT_DATE.MONTH}`
export const FORMAT_DATE_DAY: string = `${FORMAT_DATE.YEAR}${FORMAT_DATE.MONTH}${FORMAT_DATE.DAY}`
export const FORMAT_TIME: string = `${FORMAT_DATE.HOUR}${FORMAT_DATE.TIME_DELIMITER}${FORMAT_DATE.MINUTES}`
export const FORMAT_DATE_AND_TIME: string = `${FORMAT_DATE_DAY} ${FORMAT_TIME}`
export const FORMAT_MONTH_DAY: string = `${FORMAT_DATE.MONTH}${FORMAT_DATE.DAY}`
export const FORMAT_MONTH_DAY_TIME: string = `${FORMAT_DATE.MONTH}${FORMAT_DATE.DAY} ${FORMAT_TIME}`

export const FORM_LIMIT_GROUP_TITLE = 150
export const FORM_LIMIT_GROUP_DESCRIPTION = 1000

export const FORM_LIMIT_GROUP_NOTICE_TITLE = 100
export const FORM_LIMIT_GROUP_NOTICE_DESCRIPTION = 1000

export const FORM_LIMIT_HOSPITAL_NOTICE_TITLE = 30
export const FORM_LIMIT_HOSPITAL_NOTICE_DESCRIPTION = 1000

export const FORM_LIMIT_GROUP_INVITE_MESSAGE = 300

export const REACTION_TYPES = {
  RAISE_HAND: 'raiseHand'
}

export const MAX_FILE_SIZE = 10485760

export const DISPLAY_FILENAME_COUNT = 16

export const DEFAULT_SCROLL_TOP_BUTTON_BUTTOM_MERGIN = 26

export const SIGNUP_CONDITION = {
  COMMON: {
    VALUE: 0,
    KEY: 'common'
  },
  SIGNED: {
    VALUE: 1,
    KEY: 'signed'
  },
  NOT_SIGNED: {
    VALUE: 2,
    KEY: 'notSigned'
  }
}

export const IMAGE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'avif',
  'png',
  'webp',
  'apng',
  'svg',
  'gif',
  'bmp',
  'tif',
  'tiff',
  'ico',
  'cur'
]

export const COMPANY_CODE_ALL = 'ALL'
export const COMPANY_CODE_GW = 'GW'
export const COMPANY_CODE_DC = 'DC'
export const INITIAL_GROUP_NOTICE_COMMENT_FORM_HEIGHT = 61
export const TAB_BAR_CONTAINER_HEIGHT = 79

export const MS_POSITION_COMMON_LIST = ['一般', '一般社員']
export const REFRESH_INTERVAL_MSEC = 300000

export const errorMessageList = {
  common: {
    title: 'エラーが発生しました',
    lead:
      'お探しのページは、削除もしくはURLが変更されたか、閲覧権限がない可能性があります。'
  },
  notFound: {
    title: 'ページが見つかりません',
    lead: 'お探しのページは、削除もしくはURLが変更された可能性があります。'
  },
  communityPermissionDenied: {
    title: 'このコミュニティの閲覧権限がありません',
    lead: 'このコミュニティに参加しているメンバーのみ閲覧できます。'
  },
  notCommunityUser: {
    title: 'このコミュニティのユーザではありません',
    lead: 'このコミュニティに参加しているメンバーのみ閲覧できます。'
  }
}

export const errorMessageSingleList = [
  '現在ログイン中のアカウントでは閲覧できないページです。',
  '既にドクシルの会員情報が存在しています。',
  '出身大学を入力してください。',
  '卒業年を入力してください。',
  '現在の勤務先を最低一つ入力してください。',
  '専門医を入力してください。',
  '第一診療科は必須項目です。',
  '得意としている疾患・部位を入力してください。',
  'お探しのページが見つかりません。',
  'ドクシルへの会員登録が必要です。',
  '必須パラメータが不足しています',
  '必須パラメータは配列で指定してください',
  'ログインしてください',
  'このコミュニティは管理者により削除されました',
  'コミュニティが見つかりません',
  'このコミュニティの管理者ではありません',
  'コミュニティには一人以上の管理者が必要です'
]
export const TOP_PAGE_LIST = ['/', '/top/ms', '/top/medical']

export const TERMS_OF_SERVICE = 'TERMS_OF_SERVICE'

export const dummyPaperItem = {
  _id: '1',
  _source: {
    'dc:title':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    'dc:creator': ['Lorem ipsum dolor sit amet', 'consectetur adipiscing elit'],
    'dc:description': '',
    'prism:publicationName': 'Lorem ipsum',
    'prism:volume': '0',
    'prism:number': '0',
    'prism:pageRange': '1-100',
    'prism:publicationDate': '1900.01'
  },
  citation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
}

export const BUSINESS_CARD_STATUS = {
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  DELETED: 'deleted'
}

export const NOTIFICATION_TYPES = {
  BUSINESS_CARD: 'businessCard',
  INFORMATION: 'information'
}

export const USER_ROLES = {
  DOCTOR: 'doctor',
  MS: 'ms',
  CO_MEDICAL: 'co-medical'
}

export const USER_ID_WITH_ROLE_DELIMITER = '@'
export const NOTICE_FIELDS = {
  doctorCategory: '診療科',
  doctorExpertCategory: '専門医',
  searchPaperMeshList: '得意とする疾患・部位',
  surgeryMeshList: '得意とする手術・手技・治療方法',
  unacceptableMeshList: 'ミスマッチ疾患',
  introduction: '一言紹介',
  enrollmentPeriodList: '勤務先'
}

export const MESSAGE_ROOM_TYPE_GROUP = 'group'
export const MESSAGE_ROOM_TYPE_DIRECT = 'direct'

// ToDo: 他ファイルにまとめる
export const ERROR_MESSAGES = {
  DELETED_NOTICE: 'deletedNotice'
}
