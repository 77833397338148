import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2cdd5504 = () => interopDefault(import('../pages/add-home.vue' /* webpackChunkName: "pages/add-home" */))
const _bbba7c86 = () => interopDefault(import('../pages/agree-terms.vue' /* webpackChunkName: "pages/agree-terms" */))
const _c2d8f852 = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _2bc14a5b = () => interopDefault(import('../pages/doctor/index.vue' /* webpackChunkName: "pages/doctor/index" */))
const _746e044e = () => interopDefault(import('../pages/group/index.vue' /* webpackChunkName: "pages/group/index" */))
const _f44d6e5a = () => interopDefault(import('../pages/hospitals/index.vue' /* webpackChunkName: "pages/hospitals/index" */))
const _6b3387d5 = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _1b10a3c0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d8587f12 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _301c9535 = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _f449f332 = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _7c797a68 = () => interopDefault(import('../pages/passwordSetting.vue' /* webpackChunkName: "pages/passwordSetting" */))
const _29da82c6 = () => interopDefault(import('../pages/posting_information.vue' /* webpackChunkName: "pages/posting_information" */))
const _d6b8eb80 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _4768abbc = () => interopDefault(import('../pages/regist-confirm.vue' /* webpackChunkName: "pages/regist-confirm" */))
const _6c89b93e = () => interopDefault(import('../pages/retry-register.vue' /* webpackChunkName: "pages/retry-register" */))
const _7dbe1f12 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _f114243e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _d9447d5e = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _09328244 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _7ea35298 = () => interopDefault(import('../pages/unreceived_auth_code.vue' /* webpackChunkName: "pages/unreceived_auth_code" */))
const _ec59d10e = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _049463ba = () => interopDefault(import('../pages/business-card/holder/index.vue' /* webpackChunkName: "pages/business-card/holder/index" */))
const _d83cf1dc = () => interopDefault(import('../pages/business-card/requests/index.vue' /* webpackChunkName: "pages/business-card/requests/index" */))
const _1023157e = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _0cbbe568 = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _36530b4c = () => interopDefault(import('../pages/contact/form.vue' /* webpackChunkName: "pages/contact/form" */))
const _4c705fa8 = () => interopDefault(import('../pages/doctor/new/index.vue' /* webpackChunkName: "pages/doctor/new/index" */))
const _0fb24b22 = () => interopDefault(import('../pages/doctor/search.vue' /* webpackChunkName: "pages/doctor/search" */))
const _6eb47767 = () => interopDefault(import('../pages/group/new.vue' /* webpackChunkName: "pages/group/new" */))
const _4d3e43dc = () => interopDefault(import('../pages/hospitals/edit/index.vue' /* webpackChunkName: "pages/hospitals/edit/index" */))
const _3de2aa12 = () => interopDefault(import('../pages/hospitals/search.vue' /* webpackChunkName: "pages/hospitals/search" */))
const _0f1a83f4 = () => interopDefault(import('../pages/invitation/complete.vue' /* webpackChunkName: "pages/invitation/complete" */))
const _801cdfce = () => interopDefault(import('../pages/lists/hospitals/index.vue' /* webpackChunkName: "pages/lists/hospitals/index" */))
const _23c92306 = () => interopDefault(import('../pages/lists/new.vue' /* webpackChunkName: "pages/lists/new" */))
const _0bacba59 = () => interopDefault(import('../pages/medical/confirm.vue' /* webpackChunkName: "pages/medical/confirm" */))
const _01bcc0c2 = () => interopDefault(import('../pages/medical/profile.vue' /* webpackChunkName: "pages/medical/profile" */))
const _d23a9ae2 = () => interopDefault(import('../pages/medical/signup.vue' /* webpackChunkName: "pages/medical/signup" */))
const _cf2987da = () => interopDefault(import('../pages/migration/complete.vue' /* webpackChunkName: "pages/migration/complete" */))
const _2943345c = () => interopDefault(import('../pages/migration/registered.vue' /* webpackChunkName: "pages/migration/registered" */))
const _566ea8ea = () => interopDefault(import('../pages/ms/search/index.vue' /* webpackChunkName: "pages/ms/search/index" */))
const _db39b05a = () => interopDefault(import('../pages/mypage/search.vue' /* webpackChunkName: "pages/mypage/search" */))
const _576e1e06 = () => interopDefault(import('../pages/notices/new/index.vue' /* webpackChunkName: "pages/notices/new/index" */))
const _9c7ada40 = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _d8db9018 = () => interopDefault(import('../pages/setting/mail_magazine.vue' /* webpackChunkName: "pages/setting/mail_magazine" */))
const _2e18b1f1 = () => interopDefault(import('../pages/top/medical/index.vue' /* webpackChunkName: "pages/top/medical/index" */))
const _d3f1664e = () => interopDefault(import('../pages/top/ms.vue' /* webpackChunkName: "pages/top/ms" */))
const _eb0702cc = () => interopDefault(import('../pages/doctor/new/confirm.vue' /* webpackChunkName: "pages/doctor/new/confirm" */))
const _36d3fd46 = () => interopDefault(import('../pages/doctor/new/profile/index.vue' /* webpackChunkName: "pages/doctor/new/profile/index" */))
const _5ad3d7dc = () => interopDefault(import('../pages/doctor/new/select.vue' /* webpackChunkName: "pages/doctor/new/select" */))
const _7b9b1b96 = () => interopDefault(import('../pages/group/member/add.vue' /* webpackChunkName: "pages/group/member/add" */))
const _b03a46ea = () => interopDefault(import('../pages/hospitals/edit/bed.vue' /* webpackChunkName: "pages/hospitals/edit/bed" */))
const _744368bc = () => interopDefault(import('../pages/hospitals/edit/medical_staff.vue' /* webpackChunkName: "pages/hospitals/edit/medical_staff" */))
const _6d8a03f5 = () => interopDefault(import('../pages/hospitals/edit/open_bed/index.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/index" */))
const _4728ecc0 = () => interopDefault(import('../pages/hospitals/edit/outpatient/index.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/index" */))
const _0780b18e = () => interopDefault(import('../pages/hospitals/edit/patient_date.vue' /* webpackChunkName: "pages/hospitals/edit/patient_date" */))
const _0e8c3103 = () => interopDefault(import('../pages/hospitals/edit/result.vue' /* webpackChunkName: "pages/hospitals/edit/result" */))
const _0029ffb0 = () => interopDefault(import('../pages/lists/hospitals/add.vue' /* webpackChunkName: "pages/lists/hospitals/add" */))
const _09b98178 = () => interopDefault(import('../pages/notices/new/select.vue' /* webpackChunkName: "pages/notices/new/select" */))
const _124c8903 = () => interopDefault(import('../pages/doctor/new/profile/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/new/profile/doctorExpertCategory" */))
const _03062c8b = () => interopDefault(import('../pages/doctor/new/profile/pending.vue' /* webpackChunkName: "pages/doctor/new/profile/pending" */))
const _186e1e03 = () => interopDefault(import('../pages/doctor/new/profile/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/searchPaperMeshList" */))
const _d8eed098 = () => interopDefault(import('../pages/doctor/new/profile/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/surgeryMeshList" */))
const _781f9f7c = () => interopDefault(import('../pages/doctor/new/profile/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/unacceptableMeshList" */))
const _9b2e34a8 = () => interopDefault(import('../pages/hospitals/edit/outpatient/edit.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/edit" */))
const _42c77a4b = () => interopDefault(import('../pages/doctor/new/profile/editHospital/_id.vue' /* webpackChunkName: "pages/doctor/new/profile/editHospital/_id" */))
const _44427f9d = () => interopDefault(import('../pages/hospitals/edit/open_bed/_id.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/_id" */))
const _44bc9ae2 = () => interopDefault(import('../pages/group/notice/_id/index.vue' /* webpackChunkName: "pages/group/notice/_id/index" */))
const _c2b2767e = () => interopDefault(import('../pages/lists/hospitals/_id.vue' /* webpackChunkName: "pages/lists/hospitals/_id" */))
const _0cd891fc = () => interopDefault(import('../pages/group/notice/_id/reaction.vue' /* webpackChunkName: "pages/group/notice/_id/reaction" */))
const _1c308074 = () => interopDefault(import('../pages/doctor/_id/index.vue' /* webpackChunkName: "pages/doctor/_id/index" */))
const _11cd8444 = () => interopDefault(import('../pages/group/_id/index.vue' /* webpackChunkName: "pages/group/_id/index" */))
const _1eb963fb = () => interopDefault(import('../pages/hospitals/_id.vue' /* webpackChunkName: "pages/hospitals/_id" */))
const _5c3b7b7d = () => interopDefault(import('../pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _8650c6e6 = () => interopDefault(import('../pages/ms/_id/index.vue' /* webpackChunkName: "pages/ms/_id/index" */))
const _6f8e0da0 = () => interopDefault(import('../pages/notices/_id/index.vue' /* webpackChunkName: "pages/notices/_id/index" */))
const _451512e2 = () => interopDefault(import('../pages/notification/_id.vue' /* webpackChunkName: "pages/notification/_id" */))
const _b982226a = () => interopDefault(import('../pages/papers/_id.vue' /* webpackChunkName: "pages/papers/_id" */))
const _c9253e06 = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))
const _34732cc9 = () => interopDefault(import('../pages/doctor/_id/edit/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/index" */))
const _594b0688 = () => interopDefault(import('../pages/group/_id/edit.vue' /* webpackChunkName: "pages/group/_id/edit" */))
const _4f4cfef4 = () => interopDefault(import('../pages/group/_id/history.vue' /* webpackChunkName: "pages/group/_id/history" */))
const _3e3b2e1b = () => interopDefault(import('../pages/group/_id/member/index.vue' /* webpackChunkName: "pages/group/_id/member/index" */))
const _b6bd973c = () => interopDefault(import('../pages/ms/_id/edit/index.vue' /* webpackChunkName: "pages/ms/_id/edit/index" */))
const _27b5772f = () => interopDefault(import('../pages/notices/_id/edit/index.vue' /* webpackChunkName: "pages/notices/_id/edit/index" */))
const _9f5fa2a2 = () => interopDefault(import('../pages/rooms/_id/edit.vue' /* webpackChunkName: "pages/rooms/_id/edit" */))
const _04d3e087 = () => interopDefault(import('../pages/rooms/_id/members/index.vue' /* webpackChunkName: "pages/rooms/_id/members/index" */))
const _a0b0b452 = () => interopDefault(import('../pages/doctor/_id/edit/category.vue' /* webpackChunkName: "pages/doctor/_id/edit/category" */))
const _782e0320 = () => interopDefault(import('../pages/doctor/_id/edit/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/_id/edit/doctorExpertCategory" */))
const _3a104ada = () => interopDefault(import('../pages/doctor/_id/edit/email.vue' /* webpackChunkName: "pages/doctor/_id/edit/email" */))
const _2cb94f83 = () => interopDefault(import('../pages/doctor/_id/edit/papers/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/index" */))
const _d0d32774 = () => interopDefault(import('../pages/doctor/_id/edit/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/searchPaperMeshList" */))
const _3737cb75 = () => interopDefault(import('../pages/doctor/_id/edit/specialty.vue' /* webpackChunkName: "pages/doctor/_id/edit/specialty" */))
const _f77f0112 = () => interopDefault(import('../pages/doctor/_id/edit/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/surgeryMeshList" */))
const _29d1aa5f = () => interopDefault(import('../pages/doctor/_id/edit/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/unacceptableMeshList" */))
const _7625be67 = () => interopDefault(import('../pages/group/_id/notice/new.vue' /* webpackChunkName: "pages/group/_id/notice/new" */))
const _425d4d6f = () => interopDefault(import('../pages/notices/_id/edit/select.vue' /* webpackChunkName: "pages/notices/_id/edit/select" */))
const _f73ad7d4 = () => interopDefault(import('../pages/rooms/_id/members/add.vue' /* webpackChunkName: "pages/rooms/_id/members/add" */))
const _ac943e6c = () => interopDefault(import('../pages/doctor/_id/edit/papers/delete.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/delete" */))
const _984478fc = () => interopDefault(import('../pages/doctor/_id/edit/hospitals/_index.vue' /* webpackChunkName: "pages/doctor/_id/edit/hospitals/_index" */))
const _63c81d6a = () => interopDefault(import('../pages/rooms/_id/medias/_mediaId.vue' /* webpackChunkName: "pages/rooms/_id/medias/_mediaId" */))
const _516900a9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-home",
    component: _2cdd5504,
    name: "add-home"
  }, {
    path: "/agree-terms",
    component: _bbba7c86,
    name: "agree-terms"
  }, {
    path: "/confirm",
    component: _c2d8f852,
    name: "confirm"
  }, {
    path: "/doctor",
    component: _2bc14a5b,
    name: "doctor"
  }, {
    path: "/group",
    component: _746e044e,
    name: "group"
  }, {
    path: "/hospitals",
    component: _f44d6e5a,
    name: "hospitals"
  }, {
    path: "/invitation",
    component: _6b3387d5,
    name: "invitation"
  }, {
    path: "/login",
    component: _1b10a3c0,
    name: "login"
  }, {
    path: "/mypage",
    component: _d8587f12,
    name: "mypage"
  }, {
    path: "/notices",
    component: _301c9535,
    name: "notices"
  }, {
    path: "/notification",
    component: _f449f332,
    name: "notification"
  }, {
    path: "/passwordSetting",
    component: _7c797a68,
    name: "passwordSetting"
  }, {
    path: "/posting_information",
    component: _29da82c6,
    name: "posting_information"
  }, {
    path: "/profile",
    component: _d6b8eb80,
    name: "profile"
  }, {
    path: "/regist-confirm",
    component: _4768abbc,
    name: "regist-confirm"
  }, {
    path: "/retry-register",
    component: _6c89b93e,
    name: "retry-register"
  }, {
    path: "/rooms",
    component: _7dbe1f12,
    name: "rooms"
  }, {
    path: "/search",
    component: _f114243e,
    name: "search"
  }, {
    path: "/signup",
    component: _d9447d5e,
    name: "signup"
  }, {
    path: "/terms",
    component: _09328244,
    name: "terms"
  }, {
    path: "/unreceived_auth_code",
    component: _7ea35298,
    name: "unreceived_auth_code"
  }, {
    path: "/welcome",
    component: _ec59d10e,
    name: "welcome"
  }, {
    path: "/business-card/holder",
    component: _049463ba,
    name: "business-card-holder"
  }, {
    path: "/business-card/requests",
    component: _d83cf1dc,
    name: "business-card-requests"
  }, {
    path: "/contact/complete",
    component: _1023157e,
    name: "contact-complete"
  }, {
    path: "/contact/confirm",
    component: _0cbbe568,
    name: "contact-confirm"
  }, {
    path: "/contact/form",
    component: _36530b4c,
    name: "contact-form"
  }, {
    path: "/doctor/new",
    component: _4c705fa8,
    name: "doctor-new"
  }, {
    path: "/doctor/search",
    component: _0fb24b22,
    name: "doctor-search"
  }, {
    path: "/group/new",
    component: _6eb47767,
    name: "group-new"
  }, {
    path: "/hospitals/edit",
    component: _4d3e43dc,
    name: "hospitals-edit"
  }, {
    path: "/hospitals/search",
    component: _3de2aa12,
    name: "hospitals-search"
  }, {
    path: "/invitation/complete",
    component: _0f1a83f4,
    name: "invitation-complete"
  }, {
    path: "/lists/hospitals",
    component: _801cdfce,
    name: "lists-hospitals"
  }, {
    path: "/lists/new",
    component: _23c92306,
    name: "lists-new"
  }, {
    path: "/medical/confirm",
    component: _0bacba59,
    name: "medical-confirm"
  }, {
    path: "/medical/profile",
    component: _01bcc0c2,
    name: "medical-profile"
  }, {
    path: "/medical/signup",
    component: _d23a9ae2,
    name: "medical-signup"
  }, {
    path: "/migration/complete",
    component: _cf2987da,
    name: "migration-complete"
  }, {
    path: "/migration/registered",
    component: _2943345c,
    name: "migration-registered"
  }, {
    path: "/ms/search",
    component: _566ea8ea,
    name: "ms-search"
  }, {
    path: "/mypage/search",
    component: _db39b05a,
    name: "mypage-search"
  }, {
    path: "/notices/new",
    component: _576e1e06,
    name: "notices-new"
  }, {
    path: "/rooms/new",
    component: _9c7ada40,
    name: "rooms-new"
  }, {
    path: "/setting/mail_magazine",
    component: _d8db9018,
    name: "setting-mail_magazine"
  }, {
    path: "/top/medical",
    component: _2e18b1f1,
    name: "top-medical"
  }, {
    path: "/top/ms",
    component: _d3f1664e,
    name: "top-ms"
  }, {
    path: "/doctor/new/confirm",
    component: _eb0702cc,
    name: "doctor-new-confirm"
  }, {
    path: "/doctor/new/profile",
    component: _36d3fd46,
    name: "doctor-new-profile"
  }, {
    path: "/doctor/new/select",
    component: _5ad3d7dc,
    name: "doctor-new-select"
  }, {
    path: "/group/member/add",
    component: _7b9b1b96,
    name: "group-member-add"
  }, {
    path: "/hospitals/edit/bed",
    component: _b03a46ea,
    name: "hospitals-edit-bed"
  }, {
    path: "/hospitals/edit/medical_staff",
    component: _744368bc,
    name: "hospitals-edit-medical_staff"
  }, {
    path: "/hospitals/edit/open_bed",
    component: _6d8a03f5,
    name: "hospitals-edit-open_bed"
  }, {
    path: "/hospitals/edit/outpatient",
    component: _4728ecc0,
    name: "hospitals-edit-outpatient"
  }, {
    path: "/hospitals/edit/patient_date",
    component: _0780b18e,
    name: "hospitals-edit-patient_date"
  }, {
    path: "/hospitals/edit/result",
    component: _0e8c3103,
    name: "hospitals-edit-result"
  }, {
    path: "/lists/hospitals/add",
    component: _0029ffb0,
    name: "lists-hospitals-add"
  }, {
    path: "/notices/new/select",
    component: _09b98178,
    name: "notices-new-select"
  }, {
    path: "/doctor/new/profile/doctorExpertCategory",
    component: _124c8903,
    name: "doctor-new-profile-doctorExpertCategory"
  }, {
    path: "/doctor/new/profile/pending",
    component: _03062c8b,
    name: "doctor-new-profile-pending"
  }, {
    path: "/doctor/new/profile/searchPaperMeshList",
    component: _186e1e03,
    name: "doctor-new-profile-searchPaperMeshList"
  }, {
    path: "/doctor/new/profile/surgeryMeshList",
    component: _d8eed098,
    name: "doctor-new-profile-surgeryMeshList"
  }, {
    path: "/doctor/new/profile/unacceptableMeshList",
    component: _781f9f7c,
    name: "doctor-new-profile-unacceptableMeshList"
  }, {
    path: "/hospitals/edit/outpatient/edit",
    component: _9b2e34a8,
    name: "hospitals-edit-outpatient-edit"
  }, {
    path: "/doctor/new/profile/editHospital/:id?",
    component: _42c77a4b,
    name: "doctor-new-profile-editHospital-id"
  }, {
    path: "/hospitals/edit/open_bed/:id",
    component: _44427f9d,
    name: "hospitals-edit-open_bed-id"
  }, {
    path: "/group/notice/:id",
    component: _44bc9ae2,
    name: "group-notice-id"
  }, {
    path: "/lists/hospitals/:id",
    component: _c2b2767e,
    name: "lists-hospitals-id"
  }, {
    path: "/group/notice/:id?/reaction",
    component: _0cd891fc,
    name: "group-notice-id-reaction"
  }, {
    path: "/doctor/:id",
    component: _1c308074,
    name: "doctor-id"
  }, {
    path: "/group/:id",
    component: _11cd8444,
    name: "group-id"
  }, {
    path: "/hospitals/:id",
    component: _1eb963fb,
    name: "hospitals-id"
  }, {
    path: "/invitation/:id",
    component: _5c3b7b7d,
    name: "invitation-id"
  }, {
    path: "/ms/:id",
    component: _8650c6e6,
    name: "ms-id"
  }, {
    path: "/notices/:id",
    component: _6f8e0da0,
    name: "notices-id"
  }, {
    path: "/notification/:id",
    component: _451512e2,
    name: "notification-id"
  }, {
    path: "/papers/:id?",
    component: _b982226a,
    name: "papers-id"
  }, {
    path: "/rooms/:id",
    component: _c9253e06,
    name: "rooms-id"
  }, {
    path: "/doctor/:id/edit",
    component: _34732cc9,
    name: "doctor-id-edit"
  }, {
    path: "/group/:id/edit",
    component: _594b0688,
    name: "group-id-edit"
  }, {
    path: "/group/:id/history",
    component: _4f4cfef4,
    name: "group-id-history"
  }, {
    path: "/group/:id/member",
    component: _3e3b2e1b,
    name: "group-id-member"
  }, {
    path: "/ms/:id?/edit",
    component: _b6bd973c,
    name: "ms-id-edit"
  }, {
    path: "/notices/:id/edit",
    component: _27b5772f,
    name: "notices-id-edit"
  }, {
    path: "/rooms/:id/edit",
    component: _9f5fa2a2,
    name: "rooms-id-edit"
  }, {
    path: "/rooms/:id/members",
    component: _04d3e087,
    name: "rooms-id-members"
  }, {
    path: "/doctor/:id/edit/category",
    component: _a0b0b452,
    name: "doctor-id-edit-category"
  }, {
    path: "/doctor/:id/edit/doctorExpertCategory",
    component: _782e0320,
    name: "doctor-id-edit-doctorExpertCategory"
  }, {
    path: "/doctor/:id/edit/email",
    component: _3a104ada,
    name: "doctor-id-edit-email"
  }, {
    path: "/doctor/:id/edit/papers",
    component: _2cb94f83,
    name: "doctor-id-edit-papers"
  }, {
    path: "/doctor/:id/edit/searchPaperMeshList",
    component: _d0d32774,
    name: "doctor-id-edit-searchPaperMeshList"
  }, {
    path: "/doctor/:id/edit/specialty",
    component: _3737cb75,
    name: "doctor-id-edit-specialty"
  }, {
    path: "/doctor/:id/edit/surgeryMeshList",
    component: _f77f0112,
    name: "doctor-id-edit-surgeryMeshList"
  }, {
    path: "/doctor/:id/edit/unacceptableMeshList",
    component: _29d1aa5f,
    name: "doctor-id-edit-unacceptableMeshList"
  }, {
    path: "/group/:id/notice/new",
    component: _7625be67,
    name: "group-id-notice-new"
  }, {
    path: "/notices/:id/edit/select",
    component: _425d4d6f,
    name: "notices-id-edit-select"
  }, {
    path: "/rooms/:id/members/add",
    component: _f73ad7d4,
    name: "rooms-id-members-add"
  }, {
    path: "/doctor/:id/edit/papers/delete",
    component: _ac943e6c,
    name: "doctor-id-edit-papers-delete"
  }, {
    path: "/doctor/:id/edit/hospitals/:index",
    component: _984478fc,
    name: "doctor-id-edit-hospitals"
  }, {
    path: "/rooms/:id/medias/:mediaId?",
    component: _63c81d6a,
    name: "rooms-id-medias-mediaId"
  }, {
    path: "/",
    component: _516900a9,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
